module.exports = [{
      plugin: require('/opt/build/repo/node_modules/@talves/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"enableIdentityWidget":true,"publicPath":"backstage","htmlTitle":"Content Manager","modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingID":"G-K4X8HFZS5M","exclude":[]},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
